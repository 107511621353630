
import { defineComponent, ref } from 'vue';
import DateRangePicker from '@/components/date-range-picker/Index.vue';
import { useDailyData } from '@/composables/api';
import dayjs from 'dayjs';

export default defineComponent({
  components: {
    DateRangePicker
  },
  setup() {
    const page = ref(1);
    const date = ref([]); // for api
    const { data, isLoading, isFetching, refetch } = useDailyData({ page, date });

    const dateRange = ref([]);

    const getDateRange = (_date: any) => {
      date.value = _date;
    };

    const setDateRange = (date: any) => {
      dateRange.value = [];
      date.forEach(element => {
        dateRange.value.push(element);
      });
      getDateRange(date);
    };

    const formatOrderSuccessRate = (orderSuccessRate: number) => {
      return orderSuccessRate ? Math.round(orderSuccessRate * 100) / 100 : 'N/A';
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      setDateRange,
      formatOrderSuccessRate,
      date,
      dateRange
    };
  }
});
